/* eslint-disable consistent-return */
/* eslint-disable prefer-destructuring */
import BCardCode from '@core/components/b-card-code'
import {
  BNav, BNavItem, BNavItemDropdown, BDropdownDivider, BDropdownItem, BForm, BFormInput, BRow, BCol,
  BFormGroup, BButton, BCard, BDropdown, BMedia, BMediaAside, BAvatar, BMediaBody, BOverlay, BSidebar, VBToggle, BFormRadioGroup, BFormRadio,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import VSwatches from 'vue-swatches'
import 'vue-swatches/dist/vue-swatches.css'

import { golfClass } from '@/api/golf-class'
import { courseSetting } from '@/api/course-setting'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DeleteDialog from '@core/components/delete-dialog/DeleteDialog.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

export default {
  name: 'golfClass',
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormRadioGroup,
    BFormRadio,
    BSidebar,
    BCardCode,
    BNav,
    BNavItem,
    BNavItemDropdown,
    BDropdownDivider,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    vSelect,
    BRow,
    BCol,
    BButton,
    BCard,
    BDropdown,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BOverlay,
    VSwatches,
    'delete-dialog': DeleteDialog,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      humanDate: null,
      selected: null,
      option: [{ title: 'Square' }, { title: 'Rectangle' }, { title: 'Rombo' }, { title: 'Romboid' }],
      options: [
        { text: 'Bagtag', value: 'bagtag' },
        { text: 'Số tủ locker', value: 'locker' },
      ],
      detail: {},
      listGolfClass: [],
      optionsTypeGuest: [
        { text: 'Member', value: 'MEMBER' },
        { text: 'Visitor', value: 'VISITOR' },
      ],
      listCourse: [],
      sidebarAddNewGolfClass: false,
      objectAdd: {
        Color: 'Red',
        CourseId: [],
        CustomerType: 'MEMBER',
        GolfClassCode: '',
        GolfClassName: '',
        ConditionalRule: '',
      },
      search: {
        KeyWord: null,
        CourseId: [],
        Lang: this.$i18n.locale,
      },
      language: this.$i18n.locale,
      showAddNew: false,
      required,
      email,
    }
  },
  created() {
    this.getListCourseAndTee()
  },
  methods: {
    getApiListGolfClass() {
      golfClass
        .getListGolfClass(this.search)
        .then(res => {
          if (res) {
            this.listGolfClass = res.Data.GolfClass
            if (this.listGolfClass.length > 0) {
              this.detail = this.listGolfClass[0]
            }
          }
        })
    },
    detailGolfClass(item) {
      this.detail = { ...item }
    },
    getListCourseAndTee() {
      // Lấy danh sách sân
      courseSetting
        .getListCourse()
        .then(res => {
          if (res) {
            this.listCourse = res.Data.Course
            this.search.courseId = this.listCourse.map(x => x.Id)
            return this.getApiListGolfClass()
          }
        })
    },
    addNew() {
      this.$refs.addlRule.validate().then(success => {
        if (success) {
          const boby = {
            GolfClass: this.objectAdd,
          }
          golfClass
            .addNewGolfClass(boby)
            .then(res => {
              if (res) {
                this.showResToast(res)
                this.getApiListGolfClass()
                this.showAddNew = false
                this.objectAdd = {
                  Color: 'Red',
                  CourseId: [],
                  CustomerType: 'MEMBER',
                  GolfClassCode: '',
                  GolfClassName: '',
                  ConditionalRule: '',
                }
              }
            })
        }
      })
    },
    updateGolfClass() {
      this.$refs.detailRule.validate().then(success => {
        if (success) {
          const boby = {
            GolfClass: {
              Id: this.detail.Id,
              Color: this.detail.Color,
              CourseId: this.detail.CourseId,
              CustomerType: this.detail.CustomerType,
              GolfClassCode: this.detail.GolfClassCode,
              GolfClassName: this.detail.GolfClassName,
              ConditionalRule: (this.detail.ConditionalRule) ? this.detail.ConditionalRule : '',
            },
          }
          golfClass
            .updateGolfClass(boby)
            .then(res => {
              if (res) {
                this.showResToast(res)
                this.getApiListGolfClass()
              }
            })
        }
      })
    },
    changeStatusGolfClass() {
      const boby = {
        GolfClass: {
          Id: this.detail.Id,
        },
      }
      golfClass
        .changeStatusGolfClass(this.detail.InActive, boby)
        .then(res => {
          if (res) {
            this.showResToast(res)
            this.detail.InActive = !this.detail.InActive
          }
        })
    },
    removeGolfClass(data) {
      const boby = {
        GolfClass: {
          Id: data.Id,
        },
      }
      golfClass
        .deleteGolfClass(boby)
        .then(res => {
          if (res) {
            this.getApiListGolfClass()
          }
        })
    },
  },
}
